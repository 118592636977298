import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IrisHeaderComponent } from './components/header/header.component';
import { IrisHeaderNotificationsComponent } from './components/header-notifications/header-notifications.component';
import { IrisHeaderSearchComponent } from './components/header-search/header-search.component';
import { IrisHeaderProfileInfoComponent } from './components/header-profile-info/header-profile-info.component';
import { IrisHeaderDropdownMenuComponent } from './components/header-dropdown-menu/header-dropdown-menu.component';
import { IrisHeaderClockComponent } from './components/header-clock/header-clock.component';
import { IrisMainMenuService } from '@iris/common/services/main-menu.service';
import { IrisNotificationsUnlockModalComponent } from './components/header-notifications/notifications-unlock-modal/notifications-unlock-modal.component';
import { IrisModalContentModule } from '@iris/common/modules/iris-modal-content/iris-modal-content.module';
import { HeaderHelpComponent } from './components/header-help/header-help.component';
import { IrisHeaderSettingsComponent } from '@iris/modules/header/components/header-settings/header-settings.component';
import { IrisHeaderSearchResultsCardsModule } from './components/header-search/search-results-cards/search-results-cards.module';
import { IrisHeaderSearchResultCardTemplateModule } from './components/header-search/search-results-cards/card-template/card-template.module';
import { IrisAboutModule } from '@iris/common/modules/about/about.module';
import { IrisPreviewFileModule } from '@iris/common/modules/preview-file/preview-file.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IrisExternalCommonLibsModule } from '@iris/common/modules/external-common-libs/external-common-libs.module';
import { IrisUserWsModule } from '@iris/common/modules/user-common/modules/user-ws/user-ws.module';
import { SafeHtmlPipeModule } from '@iris/common/modules/pipes-common/pipes/dom-sanitizer/safe-html.module';
import { IrisTimeModule } from '@iris/common/modules/time';
import { IrisProjectSelectModule } from '@iris/common/modules/fields/project-select';
import { IrisCommonPipesModule } from '@iris/common/modules/pipes-common/common-pipes.module';
import { HeaderTitleComponent } from './components/header-title/header-title.component';
import { IrisScreensaverModule } from '@iris/common/modules/iris-screensaver/screensaver.module';
import { MatMenuModule } from '@angular/material/menu';
import { IrisFilesService } from '@iris/common/modules/dms/services/files.service';

@NgModule({
  imports: [
    IrisAboutModule,
    IrisExternalCommonLibsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IrisUserWsModule,
    IrisPreviewFileModule,
    RouterModule,
    IrisModalContentModule,
    IrisHeaderSearchResultsCardsModule,
    IrisHeaderSearchResultCardTemplateModule,
    SafeHtmlPipeModule,
    IrisTimeModule,
    IrisProjectSelectModule,
    IrisCommonPipesModule,
    IrisScreensaverModule,
    MatMenuModule,
  ],
  declarations: [
    IrisHeaderComponent,
    IrisHeaderNotificationsComponent,
    IrisHeaderSearchComponent,
    IrisHeaderProfileInfoComponent,
    IrisHeaderDropdownMenuComponent,
    IrisHeaderClockComponent,
    IrisHeaderSettingsComponent,
    IrisNotificationsUnlockModalComponent,
    HeaderHelpComponent,
    HeaderTitleComponent,
  ],
  exports: [IrisHeaderComponent],
  providers: [
    IrisMainMenuService,
    IrisFilesService,
  ],
})
export class IrisHeaderModule {
}
