import { AbstractDatetime } from '@iris/common/modules/date';

const CURRENT_YEAR = new Date().getFullYear();
const EASTER_DATE = calculateEasterDate(CURRENT_YEAR);

const SPECIAL_EVENTS = [
  {
    'name': 'Christmas',
    'file': 'christmas.min.css',
    'date': new Date(CURRENT_YEAR, 11, 24),
  },
  {
    'name': 'Easter',
    'file': 'easter.min.css',
    'date': new Date(CURRENT_YEAR, EASTER_DATE.month - 1, EASTER_DATE.day),
  },
  {
    'name': 'Halloween',
    'file': 'halloween.min.css',
    'date': new Date(CURRENT_YEAR, 9, 31),
  },
];

function calculateEasterDate(year: number): Record<string, number> {
  const C = Math.floor(year / 100);
  const N = year - 19 * Math.floor(year / 19);
  const K = Math.floor((C - 17) / 25);
  let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
  I = I - 30 * Math.floor((I / 30));
  I = I - Math.floor(I / 28) * (1 - Math.floor(I / 28) * Math.floor(29 / (I + 1)) * Math.floor((21 - N) / 11));
  let J = year + Math.floor(year / 4) + I + 2 - C + Math.floor(C / 4);
  J = J - 7 * Math.floor(J / 7);
  const L = I - J;
  const M = 3 + Math.floor((L + 40) / 44);
  const D = L + 28 - 31 * Math.floor(M / 4);
  return { 'month': M, 'day': D };
}

function calculateEventPeriod(eventDate: Date, eventName: string): Record<string, Date> {
  const parseDate = AbstractDatetime.parse(eventDate);
  if (eventName === 'Christmas') {
    return {
      'start': parseDate.minus({ week: 1 }).toJSDate(),
      'end': parseDate.plus({ days: 8 }).toJSDate(),
    };
  } else {
    return {
      'start': parseDate.minus({ days: 6 }).toJSDate(),
      'end': parseDate.plus({ days: 1 }).toJSDate(),
    };
  }
}

export function generateEventsStylesLink(): void {
  const now = new Date();
  const event = SPECIAL_EVENTS.map(e => Object.assign(e, calculateEventPeriod(e.date, e.name))).find(r => {
    return now >= r.start && now <= r.end;
  });

  if (event) {
    const link = document.createElement('link');
    const styleUrl = 'assets/special-events/' + event.file;
    Object.assign(link, {
      rel: 'stylesheet',
      type: 'text/css',
      media: 'all',
      href: styleUrl,
    });
    document.head.appendChild(link);
  }
}
