import { NgModule } from '@angular/core';
import { IrisUserWSComponent } from './components/user-ws.component';
import { IrisCommonModule } from '../../../common/common.module';
import { IrisUserAvatarModule } from '@iris/common/modules/user-common/modules/user-avatar/user-avatar.module';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

@NgModule({
  imports: [
    IrisCommonModule,
    IrisUserAvatarModule,
    NgxTippyModule,
  ],
  declarations: [IrisUserWSComponent],
  exports: [IrisUserWSComponent],
})
export class IrisUserWsModule {
}
