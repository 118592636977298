import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Inject,
  Optional,
  HostBinding,
  HostListener,
  ViewChild, ElementRef, Host,
} from '@angular/core';
import { NgControl, NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import { BaseControlAccessorComponent } from '@iris/common/modules/fields/base-control/base-control-accessor.component';
import { TranslateService } from '@ngx-translate/core';
import { FloatLabelType } from '../field-label.type';
import { ValidationMessageResolverService } from '@iris/common/modules/fields/validation/validation-message-resolver/validation-message-resolver.service';
import { EnhancedControlDirective } from '@iris/common/modules/fields/base-control/enhanced-control.component';
import { ErrorStateMatcher } from '@angular/material/core';
import { ErrorMatcherOnChange } from '@iris/common/modules/fields/base-control/base-error-matcher';

const SPECIAL_CHARACTERS = ['e', 'E', '+'];

@Component({
  selector: 'iris-number-field',
  templateUrl: './number-field.component.html',
  styleUrls: ['./number-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [{
    directive: EnhancedControlDirective,
    inputs: ['stylingMode'],
  }],
  providers: [{ provide: ErrorStateMatcher, useClass: ErrorMatcherOnChange }],
})
export class IrisNumberFieldComponent extends BaseControlAccessorComponent {
  @Input() floatLabel: FloatLabelType = 'auto';
  @Input() placeholder: string;

  @Input() step = 1;
  @Input() showSuffix = true;
  @Input() showClear = false;

  @Input() min: number;
  @Input() max: number;
  @Input() @HostBinding('class.readonly') readonly = false;

  @Input() showError = true;
  @Input() appearance = 'fill';
  @Input() hintPrefix = '';

  // value greater than max and less than min is restricted
  @Input() areValuesClamped = false;

  @Input() set autoFocus(focus: boolean) {
    if (focus) {
      setTimeout(() => this.inputElementRef.nativeElement.focus());
    }
  }

  @HostBinding('class') hostClass = 'iris-form-field';

  // temporary solution, number-field validation should be reconsidered
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
    SPECIAL_CHARACTERS.includes(event.key) && event.preventDefault();
  }

  @ViewChild('input') inputElementRef: ElementRef<HTMLInputElement>;

  focused: boolean;
  private _timeoutId: NodeJS.Timeout = null;
  private _currentWheelTime = 0;
  private _lastWheelTimeStart = 0;
  private _focusedBeforeWheel: boolean;
  private readonly _wheelPause = 100;

  get floatLabelType(): FloatLabelType {
    /*
    * If the user keeps scrolling and the focus has been set before,
    * we need to keep the label of the field unchanged,
    * so we always display the label above the field
    * */
    return this.floatLabel === 'auto' &&
           this._lastWheelTimeStart &&
           this.isScrolling &&
           this._focusedBeforeWheel ? 'always' : this.floatLabel;
  }

  private get isScrolling(): boolean {
    const currentTime = new Date().getTime();
    return !this._lastWheelTimeStart || currentTime - this._lastWheelTimeStart < this._wheelPause;
  }

  constructor(
    ngControl: NgControl,
    @Optional() @Inject(NG_VALIDATORS) validators: (Function | Validator)[],
    private readonly translateService: TranslateService,
    private readonly validationMessageResolver: ValidationMessageResolverService,
    @Host() public readonly enhancedControl: EnhancedControlDirective,
  ) {
    super(ngControl, validators);
  }

  getValidationMessage(): string {
    return this.validationMessageResolver.getErrorMessage(this.outerControl as FormControl);
  }

  clearValue(event: Event): void {
    event.stopPropagation();

    if (this.outerControl.untouched) {
      this.outerControl.markAsTouched();
    }

    this.outerControl.markAsDirty();
    this.outerControl.setValue(null);
  }

  onBlurEvent(): void {
    this.propagateTouchedCore();
  }

  onFocusIn(event: FocusEvent): void {
    this.focused = true;
  }

  onFocusOut(event: FocusEvent): void {
    this.focused = false;
  }

  private doesWheelStart(): boolean {
    return !this._lastWheelTimeStart || this._currentWheelTime - this._lastWheelTimeStart > this._wheelPause;
  }

  /*
  * When the user tries to scroll and the focus is in a field with a number,
  * in order to disable changes in the value in the field,
  * we remove the focus from the field and
  * wait for the user to stop scrolling to put the focus back in the field
  * */
  onWheel(event: Event): void {
    this._currentWheelTime = new Date().getTime();

    if(this.doesWheelStart()) {
      this._focusedBeforeWheel = this.focused;

      if(this._focusedBeforeWheel) {
        this.inputElementRef.nativeElement.blur();
      }
    }

    if(this._timeoutId) {
      clearTimeout(this._timeoutId);
    }

    this._timeoutId = setTimeout(() => {
      if(!this.isScrolling) {
        if(this._focusedBeforeWheel) {
          this.inputElementRef.nativeElement.focus({ preventScroll: true });
        }

        this._focusedBeforeWheel = undefined;
      }
    }, this._wheelPause);

    this._lastWheelTimeStart = this._currentWheelTime;
  }
}
