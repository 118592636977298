import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IrisUserSelectInputItemComponent } from './input-item.component';
import { IrisUserWsModule } from '@iris/common/modules/user-common/modules/user-ws/user-ws.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    IrisUserWsModule,
  ],
  declarations: [IrisUserSelectInputItemComponent],
  exports: [IrisUserSelectInputItemComponent],
})
export class IrisUserSelectInputItemComponentModule { }
