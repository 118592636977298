<mat-form-field
  [appearance]="$any(enhancedControl.fieldAppearance)"
  [floatLabel]="floatLabelType"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    matInput
    type="number"
    #input
    name="iris-number-field"
    inputMode="number"
    data-test="iris_field-number"
    [formControl]="$any(outerControl)"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [min]="min"
    [max]="max"
    [step]="step"
    (wheel)="onWheel($event)"
    (focusin)="onFocusIn($event)"
    (focusout)="onFocusOut($event)"
    (blur)="onBlurEvent()"
  >
  <ng-container matSuffix *ngIf="showSuffix">
    <span *ngIf="outerControl.invalid"
          class="icon fal fa-exclamation-circle iris-form-control-append"
          aria-hidden="true"></span>

    <button mat-icon-button
            aria-label="Clear"
            *ngIf="showClear && (outerControl.value || outerControl.value === 0)"
            [disabled]="outerControl.disabled"
            (click)="clearValue($event)">
      <i class="icon fa-lg fal fa-times"></i>
    </button>

    <ng-container *ngIf="enhancedControl.fieldSuffix">
      <ng-template [cdkPortalOutlet]="enhancedControl.fieldSuffix"></ng-template>
    </ng-container>
  </ng-container>

  <mat-error *ngIf="outerControl.errors && showError">
    {{ getValidationMessage() }}
  </mat-error>

  <iris-info-icon
    matPrefix
    class="iris-field-prefix-hint"
    *ngIf="hintPrefix"
    [message]="hintPrefix"
  ></iris-info-icon>

  <mat-hint align="start">{{ hint }}</mat-hint>
  <mat-hint align="end">{{ hintRight }}</mat-hint>
</mat-form-field>
