<span class="iris-user-avatar" [ngClass]="avatarSizeClass">
    <span *ngIf="hasAvatar"
          class="image">
        <img [src]="avatarLink" alt="" />
    </span>
    <span *ngIf="!hasAvatar && userExists"
          class="user-no-avatar first-letters-avatar">
        <span>{{ userInitials }}</span>
    </span>
    <span *ngIf="!userExists"
          class="user-no-avatar gray">
        <i class="fas fa-user"></i>
    </span>
</span>
